import React, { Component } from 'react'
import * as T from '../helpers/tools'
import ElementLoader from '../helpers/ElementLoader4.js'


// TODO: refactor all this, please!

class Picture extends Component {
  render() {

    const svg = this.props.svg
    const png = this.props.png
    const alt = this.props.alt

    return (
      <picture>
        <source srcSet = { svg } type = "image/svg+xml" />
        <img src = { png } alt = { alt } />
      </picture>
    )
  }
}

class Hero extends Component {

  _isActive = false
  _hero = ( b ) => this.props.activateHero( b )

  constructor( props ) {
    super( props )
    this.state = {
      flag: "hero",
			// handle elements
			allElementsLoaded: false,
			loadedElements: [],
      count: 0,
      loadNext: null,
      part: 2,
      // index: null,
      // batch
      loadOrder: [ ], // "random" or "linear" or (specific) -- e.g. "[0,2,1]"
			batchLoaded: false,
      batchNumber: 1,
    }
    this.oneUp = this.handleNextElement.bind( this )
    // this.nextPart = this.handleNextPart.bind( this )
    // this.scroll = e => this.handleNextBatch( e )
    this.onMouseEnter = this.handleHover.bind(this);
    this.onMouseLeave = this.handleLeave.bind(this);

  }
  handleHover() {
    const title = this.props.features.state.mainTitle
    console.log( "title", title )
    document.title = `write ${ title }` 
  }
  handleLeave() { document.title = this.props.features.state.mainTitle }

  componentDidMount() {
    this._isActive = true
    this._hero( true )
    // window.addEventListener( 'videoFinished', this.nextPart )
  }
  componentWillUnmount() {
    this._isActive = false
    this._hero( false )
    // window.removeEventListener( 'videoFinished', this.nextPart )
  }
  // setIndex( i ) {
  //   if ( this._isActive ) this.setState( { index: i } )
  // }


  // handlers
  // handleNextPart() {
  //   let next = this.state.part + 1
  //   if ( next > 1 ) {
  //     next = 0
  //     window.removeEventListener( 'videoFinished', this.nextPart )
  //   }
  //   console.log( "next part:", next )
  //   this.setState( { part: next } )
  // }
	handleNextBatch( e ) {
    const s = this.state
    const i = s.loadOrder[ s.count ] || s.count
		if ( this._isActive && !s.allElementsLoaded && s.batchLoaded ) {
			const nextBatch = s.batchNumber + 1
			this.setState( {
				batchLoaded: false,
				batchNumber: nextBatch
			} )
			this.handleNextElement( i )
		}
	}
	handleNextElement( i ) {
    if ( this.state.loadNext ) {
      const oneUp = i++
      const elements = this.props.features.state.heroes
      const loadedElements = this.state.loadedElements
      const isSame = elements[ i ] === loadedElements[ loadedElements.length -1 ]
      if ( !isSame ) loadedElements.push( elements[ i ] )
      const remainingElements = elements.length - loadedElements.length
      const batchLoaded = i === this.state.batchSize * this.state.batchNumber

      if ( remainingElements === 0 ) {
        this.stopLoading()
      } else if ( this._isActive && batchLoaded ) {
        this.setState( { batchLoaded: batchLoaded } )
      } else if ( this._isActive ) {
        this.setState( { count: oneUp } )
      }
    }
  }
	stopLoading() {
		if ( this._isActive ) {
			this.setState( {
				allElementsLoaded: true,
				count: null
			} )
		}
  }

  render() {

    const flag = this.state.flag
    
    const state = this.props.features.state
    const heroes = state.heroes
    // console.log( "HEROES", this.props.features.state.heroIndex )

    // if ( heroes.length > 0 && this.state.index === null ) {
    //   console.log( "HL", heroes.length )
    //   this.setIndex( T.randomIndex( 0, heroes.length ) )
    // }


    const heroesPath = state.heroesPath
    const onClickLogo = state.heroHitArea === "logo" ? state.heroAction : null
    const onClickSection = state.heroHitArea === "section" ? state.heroAction : null

    // logo or text add-on
    // TODO: refactor
    const isButton = state.heroAddOn === "button"
    const hasAddOn = state.heroAddOn
    const hasShape = state.settings.hero.addOn.includes( "circle" )
    const hasLogo = state.hasLogo
    const logo = {
      src: {
        svg: state.logo.hero.svg,
        png: state.logo.hero.png
      }
    }
    const addOnClassName = "infobox no-select"
    const addOnId = hasLogo ? "logo" : "text-logo"
		const logoTag = ( 
      <Picture svg = { logo.src.svg } png = { logo.src.png } alt = { state.mainTitle }/> 
    )
    // console.log("button?", state.settings.hero.addOn.includes( "button" ) )
    // console.log("circle?", state.settings.hero.addOn.includes( "circle" ) )
    const shapeTag = (
      <span className = "circle shape" />
    )

    // const getShape = ( addOn ) => {
    //   const lib = [ "circle", "square" ]
    //   let shape = ""
    //   for ( let e in lib ) { 
    //     addOn.includes( e )
    //     console.log( "e", e )
    //     // if ( addOn.includes( e ) ) shape = lib[ i ] }
    //   }
    //   return shape
    // }
    // const mightHaveShape = state.settings.hero.addOn.length > 1
    // const shape = mightHaveShape ? getShape( state.settings.hero.addOn ) : null

    // console.log( "shape", shape )
    

    const textTag = ( 
      <h1>{ state.info.mainTitle }</h1> 
    )
    const withButton = (
      <button onClick = { T.createNewEmail( this.props.features.state.info.personal.email ) }>
        { hasLogo ? logoTag : ( hasShape ? shapeTag : textTag ) }
      </button>
    )

    const addOnTag = (
      <div 
        onClick = { onClickLogo } 
        className = { addOnClassName } 
        id = { addOnId }
        onMouseEnter={ this.onMouseEnter }
        onMouseLeave={ this.onMouseLeave }
      >
        { isButton ? withButton : ( hasLogo ? logoTag : textTag ) }
      </div>
    )

    // bouncer add-on
    // TODO: bouncer is buggy
    const hasBouncer = false
    const bouncerClass = state.scrollingDown ? "vanish" : "appear-delayed"
		const bouncerTag = (			
			<div className = { bouncerClass }>
				<div className = "fat" id = "bouncer-text"><span>&#x25BC;</span></div>
			</div>
    )


    const sectionClassName = this._isActive ? "appear" : "vanish"


    const index = state.heroIndex
    // const index = randomIndex( 0, heroes.length - 1 )
    // console.log( "H", heroes, heroes.length, index  )
    const i = this.state.loadOrder[ index ] || index
    const file = heroes[ i ] || ""
    const part = this.state.part
    const isCollection = Array.isArray( file )
    const hero = isCollection ? file[ part ] : file
    const element = {
      index: index,
      hero: hero,
      title: state.mainTitle,
      src: T.getFilePath( heroesPath, hero ),
      type: T.getFileType( hero ),
      loop: hero.includes( "-loop" )
    }
    const props = {
      flag: flag,
      count: index,
      className: `${ flag }-element`,
      element: element,
      oneUp: this.oneUp,
      divId: "background",
    }
    
    // console.log( "element?", element.length )
    const heroTag = (
      <ElementLoader id = "background" { ...props } key = { index } onClick = { onClickSection } />
    )

    // const isValidHero = element.type !== "invalid"
    console.log( "h", element.src )
    return (
      <section id = { flag } className = { sectionClassName } >
        { hasAddOn && addOnTag }
        { this._isActive && hasBouncer && bouncerTag }
        { hero && heroTag }
      </section>
    )
  }
}

export default Hero

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import ElementLoader from '../helpers/ElementLoader.js'


class Teaser extends Component {

	_isMounted = false

	constructor( props ) {
		super( props )
		this.state = {
			flag: "teaser",
			headerStyle: "one-word-per-line",
      get hover() { return this.flag + "-hover" }
		}
		this.onScroll = this.isInViewport.bind( this )
		this.ref = React.createRef()
	}

	componentDidMount() {
		this._isMounted = true
		this.isInViewport()
		window.addEventListener( "scroll", this.onScroll )
	}
	componentWillUnmount() {
		this._isMounted = false
		window.removeEventListener( "scroll", this.onScroll )
	}
	isInViewport() {
		const divTop = this._isMounted 
			? this.ref.current.offsetTop 
			: null
		const viewportTop = window.scrollY
		const viewportBottom = viewportTop + window.innerHeight
		if ( divTop <= viewportBottom ) {
			window.removeEventListener( "scroll", this.onScroll )
			this.props.isVisible( this.props.element )
		}
	}
	onMouseEnter( i ) {
		const className = this.ref.current.className
		this.ref.current.className = `${ className } ${ this.state.hover }`
	}
	onMouseLeave( i ) {
		const className = this.ref.current.className.replace( this.state.hover, "" )
		this.ref.current.className = className.trim()
	}
  render() {

    const flag = this.state.flag
    const props = {
			flag: flag,
			loaded: this.props.loaded,
			preloaderSrc: this.props.preloader,
			className: `${ flag }-element`,
			element: this.props.element,
			count: this.props.count,
			oneUp: this.props.oneUp,
			windowWidth: this.props.windowWidth,
			windowHeight: this.props.windowHeight,
    }
    const teaserTag = (
			<NavLink 
				to = { this.props.linkTo }
			>
        <span className = { `${ flag }-title` }>{ this.props.element.title }</span>
        <ElementLoader { ...props }/>
			</NavLink>
    )
    return (
      <div
				className = { `${ flag } ${ this.state.headerStyle }` }
				ref = { this.ref }
				onMouseEnter = { () => this.onMouseEnter() }
				onMouseLeave = { () => this.onMouseLeave() }
				onClick = { this.props.onClick }
			>
        { teaserTag }
      </div>
    )
  }
}

export default Teaser

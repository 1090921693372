export default class Digest {
  constructor(config) {
    this.config = config;
  }

  get(key) {
    return this.config[key] || new Error(`${key} not found`);
  }

  verify() {
    const customKeys = {
      // KEY and Fallback
      ASSETS_PATH: 'assets',
      VALID_ROUTES: { home: '/' },
      HEADER_STYLE: '',
      COMPONENTS: [],
      INFO: { mainTitle: 'empty toybox' },
      HERO_ACTION: '',
      HERO_ADD_ON: '',
      HERO_HIT_AREA: '',
      HEADER_NAV_ITEMS: {},
      FOOTER_NAV_ITEMS: {},
      LOGO_PATH: `${this.config.ASSETS_PATH}/logo`,
      LOGO_FILE: 'logo',
      PRELOADER: `${this.config.ASSETS_PATH}/preloader/preloader.svg`,
    };
    for (const key of customKeys) {
      if (this.config[key]) {
        console.log(`${key} found!`);
        this.config[key] = this.config[key] || customKeys[key];
      } else {
        throw new Error(`${key} not found!`);
      }
    }
  }
}

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Services from './Services';
import Socialize from './Socialize';

class HeaderNav extends Component {
  render() {
    const items = this.props.state.headerNavItems;

    // TODO: refactor social stuff
    const { info } = this.props.state;
    const hasSocial = info.hasOwnProperty('social');
    let socialButton;
    if (hasSocial) {
      if (info.social.hasOwnProperty('instagram')) {
        const { instagram } = this.props.state.info.social;
        const instagramButton = <Socialize className="instagram-button" info={instagram} icon />;
        socialButton = instagramButton;
      }
    }

    const itemsArray = [];
    for (const item in items) {
      itemsArray.push(items[item]);
    }
    const navItems = itemsArray.map((item, i) => (
      <NavLink exact to={item.path} key={i}>
        <button onClick={() => this.props.onClick(item.path)}>{ item.title }</button>
      </NavLink>
    ));

    const { personal } = this.props.state.info;
    const hasServices = personal.hasOwnProperty('services ');
    const props = {
      services: (hasServices
        ? this.props.state.info.personal.services
        : []),
      logoHover: this.props.logoHover,
    };
    const services = <Services {...props} />;

    return (
      <nav className="flexbox row">
        { hasServices && services }
        { navItems }
        { hasSocial && socialButton }
      </nav>
    );
  }
}

export default HeaderNav;

import React, { Component } from 'react';
import HeaderLogo from './HeaderLogo';
import HeaderNav from './HeaderNav';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoHover: false,
    };
    this.onMouseEnter = this.handleHover.bind(this);
    this.onMouseLeave = this.handleLeave.bind(this);
    // this.onScroll = this.isInViewport.bind( this )
    this.scroll = this.isInViewport.bind(this)

    this.ref = React.createRef()
  }

  componentDidMount() {
		this._isMounted = true
		this.isInViewport()
		window.addEventListener( "scroll", this.onScroll )
		window.addEventListener( "scrollingDown", this.scroll )
	}
	componentWillUnmount() {
		this._isMounted = false
		window.removeEventListener( "scroll", this.onScroll )
		window.addEventListener( "scrollingDown", this.scroll )
  }
  
  // TODO:
  // check if header is mounted OK
  // check scroll direction
  // check y-position: scroll, windowsize
  // check if header (+ header 2) is in (or close to) viewport
  // on scrolling back up, header should be visible
  // becoming visible means, sliding down (smoothly) from the top



	isInViewport() {
		const tagTop = this._isMounted 
			? this.ref.current.offsetTop 
			: null
		const viewportTop = window.scrollY
		const viewportBottom = viewportTop + window.innerHeight
		if ( tagTop <= viewportBottom ) {
      console.log("smaller", tagTop)
			// window.removeEventListener( "scroll", this.onScroll )
			// this.props.isVisible( this.props.element )
		}
	}

  handleHover() { this.setState({ logoHover: true }); }
  handleLeave() { this.setState({ logoHover: false }); }

  render() {
    // header hero logic
    const { heroIsActive } = this.props.state;
    const { headerStyle } = this.props.state;
    const isSecond = headerStyle === 'second';
    const className = heroIsActive && isSecond ? 'stick-to-second-section' : '';

    // TODO: get these bools differently
    const hasLogo = true;
    const hasNav = true;

    return (
      <header className={className} ref={this.ref}>
        { hasLogo && (
        <HeaderLogo
          state={this.props.state}
          onClick={this.props.onClick}
          logoHover={this.state.logoHover}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        />
        ) }
        { hasNav && (
        <HeaderNav
          state={this.props.state}
          onClick={this.props.onClick}
          logoHover={this.state.logoHover}
        />
        ) }
      </header>
    );
  }
}

export default Header;

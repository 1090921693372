import React, { Component } from 'react';
import axios from 'axios';

import Header from './partials/Header';
import Main from './partials/Main';
import Footer from './partials/Footer';

import * as C from './Custom';
import * as H from './helpers/handlers';
import * as T from './helpers/tools';
import * as scroll from './helpers/scrollers';
import Digest from './Digest.js';

// const backendPath = process.env.REACT_APP_BACKEND_URL
const backendPath = '/assets';
const PUBLIC_JSON = `${backendPath}/public.json`;
const backend = PUBLIC_JSON;

const dCustom = new Digest(C);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

      foundation: {},
      // components
      components: dCustom.get('COMPONENTS'),

      // content
      assets: [],
      backendConnected: false,
      // hero
      settings: {
        hero: dCustom.get('HERO')
      },
      //
      heroIsActive: false,
      heroAddOn: dCustom.get('HERO_ADD_ON'),
      heroes: [],
      heroesPath: `${backendPath}/hero`,
      heroIndex: 0,
      heroAction: this.handleHeroAction(dCustom.get('HERO_ACTION')),
      heroHitArea: dCustom.get('HERO_HIT_AREA'),
      playNextHero: false,
      // showcases
      showcases: [],
      showcasesPath: `${backendPath}/showcases`,

      info: dCustom.get('INFO'),
      // logo
      hasLogo: !dCustom.get('LOGO_FILE'),
      logo: {
        small: {
          jpg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-small.jpg`),
          png: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-small.png`),
          svg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-small.svg`),
        },
        medium: {
          jpg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-medium.jpg`),
          png: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-medium.png`),
          svg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-medium.svg`),
        },
        large: {
          jpg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-large.jpg`),
          png: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-large.png`),
          svg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-large.svg`),
        },
        hero: {
          jpg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-hero.jpg`),
          png: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-hero.png`),
          svg: T.getFilePath(dCustom.get('LOGO_PATH'), `${dCustom.get('LOGO_FILE')}-hero.svg`),
        },
      },

      // preloader
      preloader: dCustom.get('PRELOADER'),

      // domain settings
      mainTitle: dCustom.get('INFO').mainTitle,

      // location
      targetLocation: H.handleLocation(window.location.pathname, dCustom.get('VALID_ROUTES')),
      validRoutes: dCustom.get('VALID_ROUTES'),
      headerNavItems: dCustom.get('HEADER_NAV_ITEMS'),
      footerNavItems: dCustom.get('FOOTER_NAV_ITEMS'),

      // tech
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      scrollPosition: window.scrollY,
      scrollingDown: false,

      // logic
      headerStyle: dCustom.get('HEADER_STYLE'),

    };

    this.onResize = this.handleResize.bind(this);
    this.onScroll = this.handleScroll.bind(this);
    this.onClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    console.log( "site created by benjamin jager and frank röder")
    this.handleResize();
    const trackScroll = false
    if ( trackScroll ) window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
    if (!this.state.backendConnected) {
      axios
        .get(backend)
        .then((res) => {
          // console.log('! backend connected', backend);
          const assets = res.data;
          this.setState({
            heroes: assets.heroElements,
            heroIndex: T.randomIndex( 0, assets.heroElements.length ),
            showcases: assets.showcases || [],
            backendConnected: true,
          });

          const validFolders = assets.showcases ? assets.showcases.map(showcase => (showcase.folder)) : [];
          const valid = H.validateTarget(this.state.targetLocation, this.state.validRoutes, validFolders);
          if (!valid) this.goTo(this.state.validRoutes.home);
        });
      const valid = H.validateTarget(this.state.targetLocation, this.state.validRoutes, '');
      if (!valid) this.goTo(this.state.validRoutes.home);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  }

  goTo(target) {
    console.log('---> going to:', target);
    window.location.replace(target);
  }

  handleResize() {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    // set css var
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const storedWidth = this.state.windowWidth || vw;
    const storedHeight = this.state.windowHeight || vh;

    if (vw !== storedWidth || vh !== storedHeight) {
      this.setState({
        windowWidth: vw,
        windowHeight: vh,
      });
      const e = new Event('resizingWindow');
      window.dispatchEvent(e);
    }
  }

  handleScroll() {
    const storedY = this.state.scrollPosition;
    const y = window.scrollY;
    const scrollingDown = storedY < y;
    const e = new Event('scrollingDown');

    this.setState({
      scrollPosition: y,
      scrollingDown,
    });

    if ( scrollingDown ) window.dispatchEvent(e);
  }

  handleClick(i) {
    console.log('CLICK', i);
    if (i === 'hero!') {
      // console.log('heroAction', this.state.heroAction);
      // console.log('scroll to header');
      this.scrollTo('header');
    } else {
      this.scrollTo(0);
    }
  }

  handleHero(b) { this.setState({ heroIsActive: b }); }

  // sendMail = () => {
  //   const hasEmail = this.state.info.personal.hasOwnProperty( "email" )
  //   const email = hasEmail ? this.state.info.personal.email : null
  //   const emailTag = <Email email = { email }/>
  //   window.location.href = emailTag
  // }


  handleHeroAction(i) {
    let action;

    // TODO: check if this could live in Custom.js
    switch (i) {
      case 'createNewEmail':
        action = T.createNewEmail( dCustom.get('INFO').personal.email )
        break;
      case 'scrollToHeader':
        // console.log( "scrollToHeader" )
        action = () => {
          // const scrollPos = window.scrollY;
          // const zero = 
          // TODO: get header position
          this.scrollTo("header");
        }

        break;
      case 'nextHero':
        action = () => {
          // const heroes = this.state.heroes
          // console.log( "ACTION", heroes )
          // heroes.shift()
          const current = this.state.heroIndex
          const i = T.randomIndex( 0, this.state.heroes.length )
          let next = i === current ? i + 1 : i
          if ( next === this.state.heroes.length ) next = 0;
          this.setState({
            // heroes: heroes,
            heroIndex: next,
            playNextHero: true
          });
        };
        break;
      default:
        // console.log( "default" )
        action = 'default';
    }
    return action;
  }

  scrollTo(y) {
    const scrollPos = window.scrollY;
    console.log('up!', scrollPos);
    scroll.ySmooth({
      from: scrollPos, to: y, duration: 350, behavior: 'ease-in-out',
    });
  }


  render() {
    const E = this.state.assets.heroElements
    if ( E ) console.log( "NOW", E )
    const { state } = this;
    const props = {
      state,
      onClick: this.onClick,
    };
    const hasHeader = Object.keys(this.state.headerNavItems).length > 0;
    const hasMain = Object.keys(this.state.components).length > 0;
    const hasFooter = Object.keys(this.state.footerNavItems).length > 0;
    const isEmpty = !hasHeader && !hasMain && !hasFooter;

    const emptyMessage = (
      <div className="message uppercase appear empty">
        <h1>{ this.state.info.mainTitle }</h1>
      </div>
    );
    if (this.state.info.hasOwnProperty('personal')) {
      const { personal } = this.state.info;
      if (personal.hasOwnProperty('email')) {
        if (!personal.email.hasOwnProperty('mailto')) {
          console.log('WARNING: no email address');
        }
      }
    }

    document.title = this.state.mainTitle;

    // console.log( this.state.targetLocation )
    return (
      <div className="App">
        { hasHeader && <Header {...props} /> }
        { hasMain && <Main {...props} activateHero={b => this.handleHero(b)} /> }
        { hasFooter && <Footer {...props} /> }
        { isEmpty && emptyMessage }
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react'

import Teaser from './Teaser.js'
import * as T from '../helpers/tools.js'


class Gallery extends Component {
  _isMounted = false

  constructor( props ) {
    super( props )
    this.state = {
			// handle elements
			allElementsLoaded: false,
			loadedElements: [],
			visibleElements: [],
			count: null,
			// batch
      batchSize: this.getBatchSize(),
			batchLoaded: false,
			batchNumber: 1
    }
		// handle elements
		this.oneUp = this.handleNextElement.bind( this )
		this.isVisible = this.handleViewportLoading.bind( this )
    this.resize = this.getBatchSize.bind( this )
    this.scroll = e => this.handleNextBatch( e )
  }

  componentDidMount() {
    this._isMounted = true
    this.setState( { count: 0 } )
		window.addEventListener( "resizingWindow", this.resize )
		window.addEventListener( "scrollingDown", this.scroll )
	}

	componentWillUnmount() {
		this._isMounted = false
		window.removeEventListener( "resizingWindow", this.resize )
		window.removeEventListener( "scrollingDown", this.scroll )
	}

	getBatchSize() {
		// window dimensions
		const width = this.props.features.state.windowWidth
		const height = this.props.features.state.windowHeight
    // TODO: get css vars from json
    // --> dependent on layout specs
		const unit = 20
		const gap = unit * 2
		const pageFrame = width > 1111 ? unit * 5 : unit
    // breakpoints
    let item
    switch ( width ) {
      case width > 1365:
        item = 400
        break
      case width < 834:
        item = 320 - pageFrame * 2
        break
      default:
        item = 328
    }
		// if ( width > 1365 ) {
		// 	item = 400
		// } else if ( width < 834 ) {
		// 	item = 320 - pageFrame * 2
		// }
    // calculate batch size
		const itemWidth = item + gap
		const netWidth = ( width - pageFrame * 2 ) / itemWidth
		const columns = netWidth < 1 ? 1 : Math.floor( netWidth )
		const netHeight = ( height - pageFrame * 2 ) / itemWidth
		const rows = Math.floor( netHeight )
		const visibleItems = columns * rows
		const batchSize = visibleItems * 1
		if ( this._isMounted ) {
			this.setState( { batch: batchSize } )
		} else {
			return batchSize
		}
	}

	handleNextBatch( e ) {
		const s = this.state
		const allVisibleElementsLoaded = s.loadedElements.length >= s.visibleElements.length
		if ( this._isMounted && !allVisibleElementsLoaded && !s.allElementsLoaded && s.batchLoaded ) {
			const nextBatch = s.batchNumber + 1
			this.setState( {
				batchLoaded: false,
				batchNumber: nextBatch
			} )
			this.handleNextElement( s.count )
		}
	}

	handleNextElement( i ) {

		if ( i === this.state.count ) {

			const oneUp = i + 1
			const showcases = this.props.features.state.showcases
			const loadedElements = this.state.loadedElements
      const isSame = showcases[ i ] === loadedElements[ loadedElements.length - 1 ]
			if ( !isSame ) loadedElements.push( showcases[ i ] )
			const remainingElements = showcases.length - loadedElements.length
			const batchLoaded = i === this.state.batchSize * this.state.batchNumber			
			const visibleElements = this.state.visibleElements
			const allVisibleElementsLoaded = loadedElements.length >= visibleElements.length

			if ( remainingElements === 0 ) {
				this.stopLoading()
			} else if ( this._isMounted && batchLoaded ) {
				this.setState( { batchLoaded: batchLoaded } )
				if ( !allVisibleElementsLoaded ) this.handleNextBatch()
			} else if ( this._isMounted ) {
				this.setState( { count: oneUp } )
			}

		}

	}

	handleViewportLoading( element ) {
		const visibleElements = this.state.visibleElements
		const wasPushed = visibleElements.filter( e => { return e.index === element.index } )
		if ( !wasPushed.length ) visibleElements.push( element )
	}
	stopLoading() {
		window.removeEventListener( "scrollingDown", this.scroll )
		if ( this._isMounted ) {
			this.setState( {
				allElementsLoaded: true,
				count: null
			} )
		}
  }
  
	render() {

		const count = this.state.count
    const state = this.props.features.state
		const showcases = state.showcases
		const showcasesAvailable = this.props.features.showcasesAvailable

		const teaserItems = showcases
			? ( showcases.map( ( showcase, i ) => {
        const teaser = showcase.teaser
        const isRebel = teaser.width > teaser.height
				const className = "item " + ( isRebel ? "rebel" : "citizen" )
        const props = {
					onClick: () => this.props.features.onClick( showcase.name ),
					oneUp: this.oneUp, // handles feedback from elementLoader
					isVisible: this.isVisible, // handles viewport appearance / loading
        	count: count,
        	loaded: i <= count,
        	linkTo: `/showcase/${ showcase.folder }`,
        	preloader: state.preloader,
        	element: {
        		index: i,
        		src: T.getFilePath( `${ state.showcasesPath }/${ showcase.folder }`, teaser.name ),
        		type: T.getFileType( teaser.name ),
        		title: showcase.title,
        		width: teaser.width,
        		height: teaser.height
        	},
        	windowWidth: state.windowWidth,
        	windowHeight: state.windowHeight,
        }
        return (
          <div 
            className = { className }
						key = { showcase.id }
					>
            <Teaser { ...props } />
          </div>
        )
			} ) )
			: null

			const gallery = (
				<div className = "flexbox row freedom-below uppercase">
					{ teaserItems }
				</div>
			)

			const fetching = (
				<div className = "message uppercase appear-delayed">
					<h1>fetching showcases</h1>
				</div>
			)


		// TODO: show error message when fetching doesn’t work
		return (
			<section className = "appear grid" id = "gallery">
				{ showcasesAvailable ? gallery : fetching }
			</section>
		)

  }

}

export default Gallery

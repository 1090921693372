import React from 'react'
import ElementLoader from '../helpers/ElementLoader'
import * as T from '../helpers/tools'


class Showcase extends React.Component {

	_isMounted = false

	constructor( props ) {
		super( props )
		this.state = {
			flag: "showcase",
			showcases: this.props.features.state.showcases,
			folderId: this.props.match.params.folderId,
			get validFolders() { return this.showcases.map( ( showcase ) => ( showcase.folder ) ) },
			get index() { return this.validFolders.includes( this.folderId )
				? this.validFolders.indexOf( this.folderId )
				: null
			},
			get showcase() { return this.showcases[ this.index ] },
			// handle elements
			allElementsLoaded: false,
			loadedElements: [],
			count: null,
			// batch
      get batchSize() { return this.showcase.elements.length },
			batchLoaded: false,
			batchNumber: 1,
		}
		this.oneUp = this.handleNextElement.bind( this )
	}

	componentDidMount() {
		console.log( "mounting", this.state.showcase.name )
		this._isMounted = true
		const count = this.state.count
		if ( !count ) { this.setState( { count: 0 } ) }

		// TODO: get vimeo dimensions
		// axios
		// 	.get( vimeo )
		// 	.then( res => {
		// 		const data = res
		// 	} )
	}
	componentWillUnmount() {
		console.log( "unmounting", this.state.showcase.name )
		this._isMounted = false
	}
	
	handleNextElement( i ) {

		if ( i === this.state.count ) {

			const oneUp = i + 1
			const elements = this.state.showcase.elements
			const loadedElements = this.state.loadedElements
      const isSame = elements[ i ] === loadedElements[ loadedElements.length - 1 ]
			if ( !isSame ) loadedElements.push( elements[ i ] )
			const batchLoaded = i === this.state.batchSize * this.state.batchNumber
			const remainingElements = elements.length - loadedElements.length

			if ( remainingElements === 0 ) {
				this.stopLoading()
			} else if ( batchLoaded ) {
				this.setState( { batchLoaded: batchLoaded } )
			} else {
				if ( this._isMounted ) this.setState( { count: oneUp } )
			}

		}

	}

	stopLoading() {
		this.setState( {
			allElementsLoaded: true,
			count: null
		} )
  }

	getVimeoDimensions( src ) {
		// TODO
		// GET https://vimeo.com/api/oembed.json?url={video_url}
		const dimensions = {}
		const width = 160
		const height = 90
		dimensions.width = width
		dimensions.height = height
		return dimensions
	}

	render() {

		const count = this.state.count
		console.log( "CURRENT SC", this.state.showcase.name )
		console.log( "folderId", this.state.folderId )
		console.log( "match", this.props.match.params.folderId )

		// const match = this.props.match
		// const folderId = match.params.folderId

		const state = this.props.features.state
		const showcasesPath = state.showcasesPath

		// console.log( "Showcase index", index )
		// console.log( "Showcase", showcases[index].elements )
		// console.log( "Showcase STATE", this.state.showcase )

		// ---> if index
		// const showcase = showcases[ index ] // could be undefined
		const showcase = this.state.showcase // could be undefined
		const elements= showcase.elements

		const titleTag = (
			<div className = "big-text uppercase" id = "showcase-title">
				<h1>{ showcase.title }</h1>
				<p>{ showcase.subtitle }</p>
			</div>
		)
    // const showcaseElements = (
		// 	<Showcase
		// 		key = { id }
		// 		state = { state }
		// 		index = { index }
		// 		mountImages = { ( images ) => this.props.mountImages ( images ) }
		// 	/>
		// )

		const loaderTag = elements.map( ( e, i ) => {

			// TODO: check if type is video, image or whatever
			// e.g. vimeo has no id …				

			let width
			let height
			
			if ( e.hasOwnProperty( "imageMediaMetadata" ) ) {
				width = e.imageMediaMetadata.width
				height = e.imageMediaMetadata.height
			}
			if ( e.hasOwnProperty( "imageVideoMetadata" ) ) {
				width = e.imageVideoMetadata.width
				height = e.imageVideoMetadata.height
			}
			if ( e.hasOwnProperty( "width" ) ) {
				width = e.width
				height = e.height
			}
			const src = T.getFilePath( `${ showcasesPath }/${ showcase.folder }`, e.name )
			const type = T.getFileType( e.name )

			const element = {
				index: i,
				src: src,
				type: type,
				title: showcase.title,
				width: width,
				height: height
			}

			const flag = this.state.flag

			const props = {
				flag: flag,
				loaded: this.props.loaded,
				preloaderSrc: this.props.preloader,
				className: `${ flag }-element`,
				element: element,
				count: count,
				oneUp: this.oneUp,
				windowWidth: this.props.features.state.windowWidth,
				windowHeight: this.props.features.state.windowHeight,
			}

			return ( <ElementLoader { ...props } key = { e.id }/> )

		} )

		return (
			
			<section 
				className = "grid appear header-space freedom-below uppercase" 
				id = "showcase" 
			>

				{ titleTag }
				<div className = "flexbox column uppercase" id = "showcase-content" >
					{ loaderTag }
				</div>
				
			</section>

		)

	}

}

export default Showcase

module.exports = {

  CreateImage: (props) => {
    const img = new Image();
    img.onload = () => props.didLoad(props.src, props.oneUp(props.i));
    img.src = props.src;
    return props.loading(true);
  },

  CreateIframe: (props) => {
    const PLAYER_URL = 'https://player.vimeo.com/video/';
    const url = PLAYER_URL + props.src.split('/').pop();
    const iframe = document.createElement('iframe');
    iframe.onload = () => {
      console.log('iframe loaded', url);
      return props.didLoad(props.src, props.oneUp(props.i));
    };
    iframe.src = props.url;
    return props.loading(true);
  },

  CreateVideo: (props) => {

    const video = document.createElement('video');
    console.log( "creating", props.src )

    video.onloadstart = () => {
      console.log('video loading', props.src);
      // return props.didLoad(props.src, props.oneUp(props.i));
      // return props.oneUp( props.i )
    };
    video.oncanplay = () => {
      console.log('video can play', props.src);
      // return props.didLoad( props.src )
    };
    video.onplaying = () => {
      console.log('video playing', props.src);
    }
    video.onended = () => {
      console.log('video ended', props.src);
    }
    video.src = props.src;
    
    return props.loading(true);
  },

};

module.exports = {

  handleLocation: (pathname, validRoutes) => {
    let newPath;
    if (pathname === validRoutes.home) {
      newPath = pathname;
    } else {
      const splitPath = pathname.split('/');
      if (splitPath.length > 0) {
        if (splitPath[0] === '') {
          splitPath.shift();
        }
        for (const key in validRoutes) {
          if (`/${splitPath[0]}` === validRoutes[key]) {
            // TODO: not .showcases --> more dynamic
            if (splitPath[0] === validRoutes.showcases && splitPath.length > 1 && splitPath[1] !== '') {
              newPath = splitPath[1];
            } else {
              newPath = splitPath[0];
            }
          }
        }
      }
    }
    return newPath;
  },

  validateTarget: (target, validRoutes, validFolders) => {
    const isHome = target === validRoutes.home;
    let b = isHome;
    if (!isHome && !validFolders.includes(target)) {
      for (const k in validRoutes) {
        if (validRoutes[k] === `/${target}`) b = true;
      }
    }
    return b;
  },

};

// const checkActive = ( match, location ) => {

// 	return match ? true : false

// }

// export function getFullPath( path, folder, file ) {

//     if ( file ) {

//         if ( file.includes( "http" ) ) {
//             return file
//         } else {
//             const fullPath = path + "/" + folder + "/" + file
//             return fullPath
//         }

//     }

// }

// export function getFileType( filename ) {

// 	if ( typeof filename === "string" ) {

//         const extension = filename.split( '.' ).pop()

//         // mimeType?
// 		if ( extension === "jpg" || extension === "gif" || extension === "png" || extension === "jpeg" ) {
// 			return "image"
// 		} else if ( filename.includes( "vimeo" ) ) {
//             const vimeoPlayerUrl = "https://player.vimeo.com/video/" + filename.split( "/" ).pop()
//             return vimeoPlayerUrl
// 		} else if ( extension === "mp4" ) {
// 			return "video"
// 		} else {
// 			return "invalid"
// 		}

// 	}

// }

// export function changeLogoColor( logoPath, colorString ) {

// 	if ( typeof logoPath === "string" ) {

// 		const splitPath = logoPath.split( "/" )
// 		const logo = splitPath.pop()
// 		const reconstructedPath = splitPath.join( "/" )

// 		const filename = logo.split( '.' ).shift()
// 		const extension = logo.split( '.' ).pop()
// 		const newLogo =
// 			reconstructedPath +
// 			"/" +
// 			filename +
// 			"-" +
// 			colorString +
// 			"." +
// 			extension

// 		return newLogo
// 	}

// }

// export function cleanTitle( text ) {

//     // TODO: clean title
//     const result = text

//     return result

// }

import React from 'react';
import Email from './Email';
import Socialize from './Socialize';


// TODO: refactor copyright
const Copyright = props => (
  <div className="uppercase freedom-below" id="copyright">
    <p>{ props.c }</p>
  </div>
);

class Contact extends React.Component {
  componentDidMount() {
    // window.scroll( { top: 0 } )
  }

  render() {
    const { info } = this.props;
    const { personal } = info;
    const { social } = info;

    // TODO: create list items with map
    // TODO: get copyright text from content.json – or from where?
    const COPYRIGHT = `All images and texts are copyrighted and owned by ${personal.name}. Under no circumstances shall these digital files, images and videos be used, copied, displayed or pulled from this site without the expressed written agreement of ${personal.name}.`;

    // bools
    const hasName = personal.hasOwnProperty('name');
    const hasServices = personal.hasOwnProperty('services');
    const hasAddress = personal.hasOwnProperty('address');
    const hasPhoneNo = personal.hasOwnProperty('phoneNo');
    const hasEmail = personal.hasOwnProperty('email');
    const hasInstagram = social.hasOwnProperty('instagram');

    return (
      <section className="active-links">
        <div className="header-space uppercase big-text" id="contact">
          <h1>contact</h1>
          <ul>
            { hasName && <li>{ personal.name }</li> }
            { hasServices && <li>{ personal.services.join(' ') }</li> }
            { hasAddress && <li>{ personal.address }</li> }
          </ul>
          <ul>
            { hasPhoneNo && <li>{ personal.phoneNo }</li> }
            { hasEmail && <li><Email email={personal.email} /></li> }
          </ul>
        </div>
        { hasInstagram && <Socialize className="instagram freedom-above freedom-below" info={social.instagram} /> }
        <Copyright c={COPYRIGHT} />
      </section>
    );
  }
}

export default Contact;

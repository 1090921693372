import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// global
import './css/root.scss';
import './css/global.scss';
import './css/header.scss';
import './css/main.scss';
import './css/footer.scss';
import './css/media-queries.scss';
import './css/animations.scss';
// sections
import './css/hero.scss';
import './css/gallery.scss';
import './css/showcase.scss';
// tools
import './css/preloader.scss';
import './css/socialize.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
serviceWorker.unregister();

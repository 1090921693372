import React from 'react'

import { getDimensions } from './tools'
import Video from '../partials/Video'

export default function LoadElement( props ) {

		const dimensions = getDimensions( props )
		const style = {
			width: dimensions.width || null,
			height: dimensions.height || null
    }

  return (
    <div 
      className = { props.className } 
      style = { style }
      id = { props.id }
      onClick = { props.onClick }
    >
      <Video { ...props } />
    </div>
  )
}

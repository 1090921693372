import React from 'react'
import * as C from './creators.js'


class LoadElement extends React.Component {

	_isMounted = false
	_isLoading = false

	constructor( props ) {
		super( props )
		this.state = {
			init: this.props.count === this.props.element.index,
			preloader: this.props.count === this.props.element.index,
			progress: false,
			value: 0,
			loaded: false,
			error: false
		}

	}

	componentDidMount() {
		this._isMounted = true
	}
	componentWillUnmount() {
		this._isMounted = false
	}

	handleDidLoad( what, i ) {
    this._isLoading = false
    if ( this._isMounted ) {
      this.setState( {
        init: false,
        preloader: false,
        loaded: true
      } )
    }
		if ( i ) return i
	}

	handleSrc( src, i ) {
		console.log( "handling", src )
		const props = {
			src: src,
			loading: i => this._isLoading = i,
			didLoad: ( i ) => this.handleDidLoad( i ),
			oneUp: this.props.oneUp,
			i: i
		}
		switch ( this.props.element.type ) {
			case "image":
				C.CreateImage( props)
				break
			case "video":
				C.CreateVideo( props )
				break
			case "vimeo":
				C.CreateIframe( props )
				break
			default:
				return
		}
	}

	getDimensions( flag ) {

		let dimensions = {}

		// window		
		const wWidth = this.props.windowWidth
		const wHeight = this.props.windowHeight

		// element
		const element = this.props.element
		let originalWidth = element.width
		let originalHeight = element.height
		const ratio = originalWidth / originalHeight

		// TODO: make values dynamic
		// css values
		// vars
		const unit = 20
		const pageFrame = wWidth > 1111 ? unit * 5 : unit
		const contentWidth = wWidth - pageFrame * 2
		const maxHeight = wHeight * 0.8

		let width = originalWidth
		let height = originalHeight

		if ( flag === "teaser" ) {

			// TEASER
			const citizenWidth = 400
			const rebelWidth = citizenWidth * 1.5
			let isRebel = originalWidth === rebelWidth || originalWidth > originalHeight

			// correct original if width is incorrect
			const correctWidth = originalWidth === citizenWidth || originalWidth === rebelWidth
			if ( !correctWidth ) {
				originalWidth = isRebel ? rebelWidth : citizenWidth
				originalHeight = originalWidth / ratio
			}

			// set itemWidth
			let item = 328
			
			if ( wWidth > 1365 ) {
				item = citizenWidth
			} else if ( wWidth < 834 ) {
				item = 320 - pageFrame * 2
				if ( wWidth < item * 1.5 + pageFrame * 2 ) {
					isRebel = false
				}
			}
			const itemWidth = isRebel ? item * 1.5 : item
			width = itemWidth
			height = width / ratio

		} else if ( flag === "showcase" ) {
			// SHOWCASE	---> do i need this?
    }
    
		// handle ratio
		if ( width > contentWidth ) {
			width = contentWidth
			height = width / ratio
		}
		if ( height > maxHeight ) {
			height = maxHeight
			width = height * ratio
		}
		// return dimensions object
		dimensions = {
			width: Math.round( width ),
			height: Math.round( height )
		}
    return dimensions
    
	}

	// TODO: read vimeo json
	// get json from:
	//	https://vimeo.com/api/oembed.json?url={ encodeURI( element.src ) }
	// const width = this
	// const height = that
	// const ratio = width / height
	// return width, height, ratio
 
	render() {

		const loading = this._isLoading
		const loaded = this.state.loaded
		const init = this.props.count === this.props.element.index
		const showPreloader = !loaded && ( init || loading )

		const preloaderSrc = this.props.preloaderSrc
		const tailSpin = { background: `url( ${ preloaderSrc } ) no-repeat center center` }
		const element = this.props.element
		const i = element.index
		const src = element.src

		// TODO: poster jpg should be created automatically from video
		const poster = this.props.element.type === "video" ? src.replace( ".mp4", ".jpg" ) : null
		const flag = this.props.flag
		const dimensions = this.getDimensions( flag )
		const divStyle = {
			width: dimensions.width || null,
			height: dimensions.height || null
		}
		const divId = this.props.divId || ""
		const className = this.props.className
		const preloaderBackground = !loaded ? " preloader-background" : ""
		const divClassName = className + " appear" + preloaderBackground
		if ( init && !loading && !loaded ) this.handleSrc( src, i )
	
		// create tag
		const imgTag = (
			<figure className = "appear">
				<img
					id = { element.id }
					src = { src }
					title = { element.title }
					alt = { element.title }
				/>
			</figure>
		)
		const iframeTag = (
			<div className = "appear">
				<iframe
					src = { src }
					id = { element.id }
					title = { element.title }
					width = { element.width }
					height={ element.height }
					frameBorder = "0"
					webkitallowfullscreen = "true"
					mozallowfullscreen = "true"
					allowFullScreen
				>
				</iframe>
			</div>
		)
		const videoTag = (
			<figure className = "appear"
			ref = { loadedElement => this.ref = loadedElement }
			>
				<video
					id = { element.id }
					aria-hidden = "true"
					autoPlay
					loop
					muted
					playsInline
					src = { src }
					poster = { poster }
					tabIndex = "-1"
				/>
			</figure>
		)
		const preloaderTag = (
			<div
				className = "appear preloader"
				style = { tailSpin }
			/>
		)
		// TODO: implement progress logic
		// const progressTag = { progress && 
		// 	<progress value = { this.state.value } max = "100" />
		// }

		const getTag = ( type ) => {
			if ( loaded ) {
				let tag
				switch ( type ) {
					case "image":
						tag = imgTag
						break
					case "vimeo":
						tag = iframeTag
						break
					case "video":
						tag = videoTag
						break
					default:
						tag = null
				}
				return tag
			}
		}

		const contentTag = getTag( this.props.element.type )

		return (
			<div 
				className = { divClassName } 
				style = { divStyle }
				id = { divId }
			>
				{ contentTag }
				{ showPreloader && preloaderTag }
			</div>
		)

	}

}

export default LoadElement

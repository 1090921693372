module.exports = {

  getFilePath: (path, file) => {
    const filePath = file.includes('http')
      ? file
      : `${path}/${file}`;
      return filePath
  },

  getFileType(filename) {
    if (typeof filename === 'string') {
      const extension = filename.split('.').pop();
      // mimeType?
      if (extension === 'jpg' || extension === 'gif' || extension === 'png' || extension === 'jpeg') {
        return 'image';
      } if (filename.includes('vimeo')) {
        const vimeoPlayerUrl = `https://player.vimeo.com/video/${filename.split('/').pop()}`;
        return vimeoPlayerUrl;
      } if (extension === 'mp4') {
        return 'video';
      }
      return 'invalid';
    }
  },

  // TODO: reafctor this
  getDimensions: ( props ) => {

    console.log( "getDimensions:", props )

    let dimensions = {}
  
    // window		
    const windowWidth = props.windowWidth
    const windowHeight = props.windowHeight
  
    // element
    const e = props.element
    let originalWidth = e.width
    let originalHeight = e.height
    const ratio = originalWidth / originalHeight
  
    // TODO: make values dynamic
    // css values
    // vars
    const unit = 20
    const pageFrame = windowWidth > 1111 ? unit * 5 : unit
    const contentWidth = windowWidth - pageFrame * 2
    const maxHeight = windowHeight * 0.8
  
    let width = originalWidth
    let height = originalHeight
  
    if ( props.flag === "teaser" ) {
  
      // TEASER
      const citizenWidth = 400
      const rebelWidth = citizenWidth * 1.5
      let isRebel = originalWidth === rebelWidth || originalWidth > originalHeight
  
      // correct original if width is incorrect
      const correctWidth = originalWidth === citizenWidth || originalWidth === rebelWidth
      if ( !correctWidth ) {
        originalWidth = isRebel ? rebelWidth : citizenWidth
        originalHeight = originalWidth / ratio
      }
  
      // set itemWidth
      let item = 328
      
      if ( windowWidth > 1365 ) {
        item = citizenWidth
      } else if ( windowWidth < 834 ) {
        item = 320 - pageFrame * 2
        if ( windowWidth < item * 1.5 + pageFrame * 2 ) {
          isRebel = false
        }
      }
      const itemWidth = isRebel ? item * 1.5 : item
      width = itemWidth
      height = width / ratio
  
    }
    
    // handle ratio
    if ( width > contentWidth ) {
      width = contentWidth
      height = width / ratio
    }
    if ( height > maxHeight ) {
      height = maxHeight
      width = height * ratio
    }
    // return dimensions object
    dimensions = {
      width: Math.round( width ),
      height: Math.round( height )
    }
    return dimensions
    
  },

  createNewEmail( e ) {
    const email = e
    const mailto = email.mailto || ""
    const cc = email.cc || ""
    const bcc = email.bcc || ""
    const subject = email.subject || ""
    const body = email.body || ""
    const link = `mailto:${ mailto }?cc=${ cc }&bcc=${ bcc }&subject=${ subject }&body=${ body }`
    return () => { ( window.location.href = link ) }
  },

  randomIndex( min, max ) {
    return Math.round( Math.random() * ( max - min ) + min )
  }

};

import React, { useState, useEffect } from 'react'
import { useVideo } from 'react-use'


export default function Video( props ) {
  
  const [ isLoading, setIsLoading ] = useState( false )
  // TODO: create status handler

  const e = props.element

  useEffect( () => {
    console.log( "isLoading", isLoading, e.index )
    setIsLoading( true )
    return function cleanup() {
      setIsLoading( false )
    }
  }, [ isLoading, e ] )

  const specs = {
		"aria-hidden": "true",
    autoPlay: true,
    loop: e.loop,
    muted: true,
    playsInline: true,
    tabIndex: "-1"
  }

  const [ video ] = useVideo(
    <video ref id = { e.id } src = { e.src } { ...specs } /> 
  )

  // console.log( "e.loop", e.src, e.loop )
  // console.log( "v", ref )

  
  // function videoFinished() {
  //   console.log( "video stopped", e.src )
  //   const E = new Event('videoFinished');
  //   window.dispatchEvent( E );
  // }
  // if ( !ref.isPlaying && ref.duration > 0 && ref.time === ref.duration ) videoFinished()

  return (
    <figure>
      { video }
    </figure>
  )

}

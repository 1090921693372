module.exports = {

  ySmooth: ({
    from, to, duration, behavior,
  }) => {
    /*
		 * Scroll from initY to 0
		 * @param { number } initY - initial scroll Y
		 * @param { number } duration - transition duration
		 * @param { string } timingName - timing function name. Can be one of linear, ease-in, ease-out, ease-in-out
		 */

    const TIMINGFUNC_MAP = {
      linear: t => t,
      'ease-in': t => t * t,
      'ease-out': t => t * (2 - t),
      'ease-in-out': t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    };
    const timingFunc = TIMINGFUNC_MAP[behavior];

    let start = null;

    const step = (timestamp) => {
      start = start || timestamp;
      const progress = timestamp - start;
      // Growing from 0 to 1
      const time = Math.min(1, ((timestamp - start) / duration));
      const distance = Math.abs(from - to);
      const target = timingFunc(time) * distance;
      const movement = from > to ? from - target : from + target;

      window.scrollTo(0, movement);

      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  },

};

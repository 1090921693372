import React from 'react';

const Email = (props) => {
  const { mailto } = props.email;
  const cc = props.email.cc || '';
  const bcc = props.email.bcc || '';
  const subject = props.email.subject || '';
  const body = props.email.body || '';
  const visible = props.email.visible || mailto;
  return (
    <a href={`mailto:${mailto}?cc=${cc}&bcc=${bcc}&subject=${subject}&body=${body}`}>{ visible }</a>
  );
};
export default Email;

import React, { Component } from 'react';

class Services extends Component {
  render() {
    const showServices = this.props.logoIsShort || window.innerWidth > 800;
    const className = `services no-select ${showServices ? 'appear' : 'hide'}`;
    console.log('services?', this.props.services);
    const servicesTag = (
      <div className={className}>
        <ul>
          { this.props.services.map((item, i) => (<li key={i}>{ item }</li>)) }
        </ul>
      </div>
    );
    return servicesTag;
  }
}

export default Services;

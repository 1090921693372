import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class HeaderLogo extends Component {
  render() {
    const { home } = this.props.state.validRoutes;
    const { logo } = this.props.state;
    const { logoHover } = this.props;
    const src = {
      svg: logoHover ? logo.large.svg : logo.medium.svg,
      png: logoHover ? logo.large.png : logo.medium.png,
    };

    return (
      <NavLink
        exact
        to={home}
      >
        <div
          className="header-logo"
          id="header-logo"
          onClick={() => this.props.onClick(home)}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          <picture>
            <source srcSet={src.svg} type="image/svg+xml" />
            <img src={src.png} alt={this.props.state.mainTitle} />
          </picture>

        </div>
      </NavLink>
    );
  }
}

export default HeaderLogo;

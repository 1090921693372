import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// import Header from './Header'
import Hero from './Hero2';
import Gallery from './Gallery';
import Showcase from './Showcase';
import Contact from './Contact';
import Imprint from './Imprint';
import Privacy from './Privacy';
import Socialize from './Socialize';

class Main extends Component {
  render() {
    const { state } = this.props;
    const { onClick } = this.props;
    const showcasesAvailable = state.showcases.length > 0;
    const features = { state, onClick, showcasesAvailable };

    const social = this.props.state.info.social || '';
    const socialServices = {
      hasInstagram: social.hasOwnProperty('instagram'),
      hasTwitter: social.hasOwnProperty('twitter'),
      hasWhatever: social.hasOwnProperty('whatever'),
    };
    // TODO: what to do, if there is more than one hero element?

    const theOne = true;

    const HOME = state.validRoutes.home;
    const SHOWCASE = state.validRoutes.showcase;
    const CONTACT = state.validRoutes.contact;
    const IMPRINT = state.validRoutes.imprint;
    const PRIVACY = state.validRoutes.privacy;

    const hasHero = state.components.includes('hero');
    const hasGallery = state.components.includes('gallery');

    return (
      <Switch>

        <Route
          exact
          path={HOME}
          render={props => (
            <main>
              { hasHero && <Hero {...props} features={features} activateHero={this.props.activateHero} theOne={theOne} /> }
              { hasGallery && <Gallery {...props} features={features} /> }
              { socialServices.hasInstagram && <Socialize className="instagram freedom-below" info={this.props.state.info.social.instagram} /> }
            </main>
          )}
        />
        <Route
          exact
          path={SHOWCASE}
          render={props => (
            <main>
              { showcasesAvailable && (<Showcase {...props} features={features} />) }
              { hasGallery && <Gallery {...props} features={features} /> }
              { socialServices.hasInstagram && <Socialize className="instagram freedom-below" info={this.props.state.info.social.instagram} /> }
            </main>
          )}
        />
        <Route
          exact
          path={CONTACT}
          render={props => (
            <main>
              <Contact {...props} info={state.info} />
            </main>
          )}
        />
        <Route
          exact
          path={IMPRINT}
          render={props => (
            <main>
              <Imprint {...props} info={state.info} />
            </main>
          )}
        />
        <Route
          exact
          path={PRIVACY}
          render={props => (
            <main>
              <Privacy {...props} info={state.info} />
            </main>
          )}
        />

      </Switch>
    );
  }
}

export default Main;

import React, { Component } from 'react';

// MANUAL: ask if property exists, pass property through info onto child component, add css
// const hasPropery = social.hasOwnProperty( "example" )
// { hasPropery && <Socialize className = "example" info = { social.example }/> }

class Socialize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.onMouseEnter = this.handleHover.bind(this);
    this.onMouseLeave = this.handleLeave.bind(this);
  }

  handleHover() { this.setState({ hover: true }); }

  handleLeave() { this.setState({ hover: false }); }

  render() {
    const { hover } = this.state;
    const { icon } = this.props.info;
    const src = {
      svg: hover ? `${icon}-white.svg` : `${icon}.svg`,
      png: hover ? `${icon}-white.png` : `${icon}.png`,
    };
    const picture = (
      <picture>
        <source srcSet={src.svg} type="image/svg+xml" />
        <img src={src.png} alt={this.props.info.alt} />
      </picture>
    );
    const text = (
      <button><h4>{ this.props.info.title }</h4></button>
    );

    const isIcon = this.props.icon;

    return (
      <a
        href={this.props.info.url}
        title={this.props.info.title}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={this.props.className}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          { isIcon ? picture : text }
        </div>
      </a>
    );
  }
}

export default Socialize;

module.exports = {

	INFO: {
		mainTitle: "Lea Köhn",
		personal: {
			name: "Lea Köhn",
			email: {
				mailto: "lea@lea-koehn.de",
			},
		},
		social: {
		}
	},

	VALID_ROUTES: {
		home: "/",
	},
	COMPONENTS: [
    "hero"
	],
	// refactor hero object
	HERO: {
	// 	// action: "nextHero", // could contain a function
		addOn: [
			"button",
			"circle"
		]
	},
	// 	// hitArea: "section",
	// 	// style: ""
	// }
	HERO_ACTION: "nextHero",
	HERO_ADD_ON: "button",
	HERO_HIT_AREA: "section",
	HEADER_STYLE: "",

	// TODO: make path props dynamic
	HEADER_NAV_ITEMS: {
		// cv: {
		// 	path: "/cv",
		// 	title: "cv"
		// }
	},
	FOOTER_NAV_ITEMS: {
	},
	LOGO_PATH: "",
	LOGO_FILE: "",
	// CSS_ROOT: {
	//   unit: 20,
	//   gap: unit * 2,
	//   pageFrame: width > 1111 ? unit * 5 : unit,
	//   item: 328
	// }
  PRELOADER: "assets/preloader/tail-spin.svg"

}



// TODO: custom hero behaviour

// load second video first, then first
// play first, then second without any gaps
// then remove first
// loop second

// random hero set
// needs to happen in public.json
// or when somebody visits the site

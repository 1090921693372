import React, { Component } from 'react';
import FooterNav from './FooterNav.js';

class Footer extends Component {
  render() {
    return (
      <footer>
        <FooterNav
          state={this.props.state}
          onClick={this.props.onClick}
        />
      </footer>
    );
  }
}

export default Footer;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class FooterNav extends Component {
  render() {
    const items = this.props.state.footerNavItems;
    const itemsArray = [];
    for (const item in items) {
      itemsArray.push(items[item]);
    }
    const navItems = itemsArray.map((item, i) => (
      <NavLink exact to={item.path} key={i}>
        <button onClick={() => this.props.onClick(item.path)}>{ item.title }</button>
      </NavLink>
    ));

    return (
      <div>
        { navItems }
      </div>
    );
  }
}

export default FooterNav;
